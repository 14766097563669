.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 700px;
  background-image: url(../../../../public/images//factory-image-orange.jpg);
  @media (max-width: 768px) {
    height: 350px;
    .shadow {
      height: 350px !important;
    }
    .banner {
      margin-top: 0 !important;
      width: 80% !important;
    }
  }
  .shadow {
    position: absolute;
    top: 0;
    height: 700px;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.5) 85%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 400px;
    color: #fff;
    height: auto;
    text-align: center;
    margin-top: 70px;
    z-index: 2;
    .banner-header {
      font-weight: bold;
      font-size: 35px;
    }
    .banner-text {
      font-family: "Roboto Condensed";
      font-weight: bold;
      margin-top: 15px;
      margin-bottom: 30px;
      font-size: 18px;
    }
    .banner-button {
      font-weight: 600;
      text-decoration: none;
      color: #fff;
      position: relative;
      padding: 10px 15px;
      border-radius: 20px;
      background-color: #b25009;
      &:hover {
        background-color: #000;
      }
    }
  }
  &.contactPageSlider {
    height: 350px;
    background-position: center -370px;
    background-image: url(../../../../public/images/contact-us.jpg);
    .shadow {
      height: 350px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 85%,
        rgba(0, 0, 0, 1) 100%
      );
    }
    @media (max-width: 768px) {
      background-position: center -90px;
      .shadow {
        height: 260px !important;
      }
    }
  }
}
