.business {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #eaeaea;
  .who-we-are {
    width: 1220px;
    display: flex;

    .left-area {
      display: flex;
      flex-direction: column;
      padding-right: 80px;
      span {
        font-weight: bold;
        font-size: 20px;
        color: #b25009;
        margin-bottom: 15px;
      }
      b {
        font-size: 25px;
        color: #3f3e3e;
        margin-bottom: 30px;
      }
      p {
        color: #9b9b9b;
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 27px;
      }
    }
    .right-area {
      display: flex;
      justify-content: center;
      img {
        width: 600px;
        height: 400px;
        border-radius: 10px;
      }
    }
  }
}

// ToDo: Mobil için revize edilecek

@media (max-width: 768px) {
  .business {
    padding: 20px 10px; /* Daha küçük padding */
    flex-direction: column; /* Mobilde dikey hizalama */
    border-bottom: 1px solid #eaeaea;

    .who-we-are {
      flex-direction: column; /* Mobilde içerikleri dikey hizala */
      width: 100%; /* Tam genişlikte olsun */

      .left-area {
        padding-right: 0; /* Sağ tarafa boşluk verilmez */
        margin-bottom: 20px; /* Altına boşluk ekle, sağ tarafla ayrılsın */

        span {
          font-size: 18px; /* Yazı boyutunu küçült */
        }

        b {
          font-size: 22px; /* Başlık yazısını küçült */
        }

        p {
          font-size: 14px; /* Paragraf metnini küçült */
          line-height: 24px; /* Satır yüksekliğini ayarla */
        }
      }

      .right-area {
        justify-content: center;
        img {
          width: 100%; /* Görüntü tam genişlikte olacak şekilde ayarlanır */
          height: auto; /* Oran koruyarak yüksekliği ayarla */
          border-radius: 10px;
        }
      }
    }
  }
}
