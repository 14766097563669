.contact-header-bar {
  width: 100%;
  height: 40px;
  background-color: #fff;
  z-index: 30;
  position: relative;
  transition: width 0.3s ease-in-out;
  &.foff-header {
    width: 0;
    height: 0;
  }
}
.header {
  width: 100%;
  position: fixed; // Sabit pozisyon
  display: flex;
  justify-content: space-between;
  padding: 15px 80px 15px 110px;
  z-index: 10;
  background-color: transparent; // İlk başta saydam
  transition: background-color 0.6s ease-in-out, padding 0.3s ease-in-out; // Animasyon geçişi

  &.stickyHeader {
    background-color: #000; // Sabit konumda arka plan rengi
    padding: 15px 80px 15px 110px; // Gerekirse padding ayarla
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 21px;
    font-family: "Roboto Condensed";
    img {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
  }
  .navbar {
    display: flex;
    font-weight: bold;
    .navbar-item {
      padding: 10px;
      &:last-child {
        &:hover {
          background-color: #b25009;
          padding: 10px;
          border-radius: 5px;
        }
      }
      a {
        text-decoration: none;
      }
      button,
      a {
        font-weight: bold;
        font-size: 16px;
        padding: 0;
        background-color: transparent;
        color: #fff;
        position: relative;
        cursor: pointer;
      }
      &:not(:last-child) {
        & button,
        a {
          text-decoration: none;
          color: #fff;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 2px;
            display: block;
            margin-top: 5px;
            border-radius: 5px;
            left: 0;
            background: #fff;
            transition: width 0.2s ease, right 0.2s ease;
          }
          &:hover::after {
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }
}

.header {
  &.mobile {
    position: absolute;
    padding: 15px;
    width: auto;
    z-index: 1001;

    .mobile-menu-toggle {
      display: block;
      z-index: 1002;
      i {
        color: #fff;
        font-size: 20px;
      }
    }

    .mobile-menu {
      i {
        display: flex;
        position: relative;
        z-index: 99999;
        color: #fff;
        font-size: 15px;
        float: right;
        padding: 15px;
      }
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 80%;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 1001;
      transform: translateX(-100%);
      transition: transform 0.3s ease, background-color 0.3s ease;

      &.open {
        transform: translateX(0);
      }

      .navbar-item {
        text-align: left;
        color: #fff;
        border-bottom: 1px solid;
        margin-left: 10px;

        button,
        a {
          color: #fff;
          font-size: 18px;
          text-decoration: none;
          cursor: pointer;
          background-color: transparent;
          display: flex;
          padding: 15px;
          width: 100%;
        }
      }
    }
  }

  .closeTab {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    cursor: pointer;
    i {
      display: flex;
      position: relative;
      z-index: 99999;
      color: #fff;
      font-size: 20px;
      float: right;
      padding: 15px;
      padding-right: 10%;
    }
  }
}
