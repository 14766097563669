.footer {
  background-image: url(../../../../public//images/ftbg2.png);
  background-color: #1c2130;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: start;
  color: #fff;
  padding: 60px 100px;
  .footer-area {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 25%;
    padding: 0 30px;
    h2 {
      margin-bottom: 35px;
    }
    &.area-1 {
      width: 22%;
      span {
        margin-bottom: 15px;
        b {
          margin-right: 10px;
        }
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
    &.area-2,
    &.area-3 {
      h2 {
        margin-bottom: 30px;
      }
    }
    span {
      i {
        color: #d9d9d9;
        font-size: 18px;
        margin-right: 5px;
      }
    }
    img {
      width: 30px;
      color: white;
    }
    p {
      width: 250px;
      span {
        text-align: end;
      }
    }
  }
}

// ToDo: Mobil için revize edilecek

@media (max-width: 768px) {
  .footer {
    flex-direction: column; /* Mobilde dikey hizala */
    align-items: center; /* İçerikleri ortala */
    padding: 30px 20px; /* Padding küçült */
    background-size: cover; /* Arka plan resmi mobilde kaplamalı */

    .footer-area {
      width: 100%; /* Her alan tam genişlikte */
      padding: 20px 0; /* Yanlardan padding kaldır */
      text-align: center; /* Metinleri ortala */
      align-items: center;

      h2 {
        font-size: 20px; /* Başlık yazı boyutunu küçült */
        margin-bottom: 20px; /* Margin azalt */
      }

      &.area-1,
      &.area-2,
      &.area-3 {
        width: 100%; /* Üç alan da tam genişlikte olacak */
        padding: 10px 0; /* İçerikler arasında boşluk bırak */
      }

      span {
        display: block;
        margin-bottom: 15px; /* Spana alt boşluk ekle */
        i {
          font-size: 16px; /* İkon boyutunu küçült */
        }
      }

      img {
        width: 25px; /* Görselleri küçült */
        margin-bottom: 15px; /* Görsel altına boşluk ekle */
      }

      p {
        width: auto; /* Paragraf genişliği tam ekran genişliği olur */
        margin: 0 auto; /* Ortalayarak konumlandır */
        br {
          display: none;
        }
      }
    }
  }
}
