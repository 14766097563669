.products-header {
  background-image: url(../../../../public/images/products-header.jpg);
  height: 400px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  .shadow {
    position: absolute;
    top: 0;
    height: 300px;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.5) 85%,
      black 100%
    );
  }
  .ellipsis {
    position: relative;
    img {
      width: 100%;
      height: 400px;
      position: absolute;
    }
  }
  &.mobile {
    width: 100%;
    height: 180px;
    margin: 0px;
    padding: 0px;
    display: block;
    float: left;
    position: relative;
    .shadow {
      height: 180px;
    }
    img {
      width: 100%;
      height: 181px;
      position: absolute;
    }
  }
}
.product-page {
  padding: 20px;
  color: #333;

  .breadcrumb {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .product-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2.5em;
      color: #1c2130;
    }
  }
  img {
    max-width: 100%;
    height: 238px;
    border-radius: 10px;
  }

  .product-categories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;

    .category {
      background-color: #f9f9f9;
      border: 1px solid #eaeaea;
      border-radius: 8px;
      padding: 20px;
      margin: 10px;
      text-align: center;
      width: 280px;
      transition: transform 0.3s;

      a {
        text-decoration: none;
      }

      &:hover {
        transform: scale(1.05);
      }

      h2 {
        font-size: 1.5em;
        color: #fea629;
      }

      button {
        padding: 10px 15px;
        background-color: #fea629;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #a04008;
        }
      }
    }
  }
}
