.product-slider {
  background-image: url(../../../../public/images/wavy-pattern.png);
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 30px;
  border-bottom: 1px solid #eaeaea;
  .product-area {
    display: flex;
    width: 1220px;
    .product-image {
      img {
        width: 400px;
        height: 400px;
      }
    }
    .products {
      display: flex;
      flex-direction: column;
      width: calc(100% - 420px);
      margin-left: 20px;
      .slider-header {
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        align-items: center;
        margin: 30px 15px;
        i:hover {
          cursor: pointer;
          color: #5b5b5b;
        }
        .product-name {
          font-family: Roboto;
        }
      }
      .all-products {
        display: flex;
        padding-bottom: 20px;
        align-items: end;
        width: auto;
        overflow-x: auto;
        scrollbar-width: none;
        margin-bottom: 40px;
        cursor: grab;
        &::-webkit-scrollbar {
          display: none;
        }
        .product {
          font-size: 25px;
          font-weight: bold;
          color: #b9b9b9;
          background-color: #fff;
          user-select: none;
          margin-right: 15px;
          margin-left: 15px;
          flex-wrap: wrap;
          min-width: 170px;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          box-shadow: rgba(0, 0, 0, 0.45) 0px 13px 21px -17px;
          border-radius: 4px;
          &.active {
            color: #000 !important;
          }
          &:hover {
            color: #5b5b5b;
          }
        }
      }
      .product-context {
        font-size: 20px;
        font-family: "Roboto Condensed";
        line-height: 25px;
        color: #5d5d66;
      }
    }
  }
}

// ToDO: Mobil için revize edilecek

@media (max-width: 768px) {
  .product-slider {
    padding: 20px 10px; /* Daha küçük padding */
    flex-direction: column; /* Mobilde dikey hizalama */
    background-size: cover; /* Arka plan resminin mobilde kaplamasını sağlar */

    .product-area {
      flex-direction: column; /* Mobilde ürün alanını dikey hizala */
      width: 100%; /* Tam genişlikte olsun */

      .product-image {
        img {
          display: none;
          max-width: 100%; /* Görüntü tam genişlikte olacak şekilde ayarlanır */
          height: auto; /* Oran korunarak yüksekliği ayarla */
        }
      }

      .products {
        width: 100%; /* Ürünler alanını tam genişlikte yap */
        margin-left: 0; /* Soldan boşluk kaldır */

        .slider-header {
          font-size: 18px; /* Başlık yazı boyutunu küçült */
          margin: 20px 0; /* Margin azalt */
        }

        .all-products {
          flex-wrap: nowrap; /* Mobilde tek satırda kaydırma */
          margin-bottom: 20px; /* Alt boşluk küçült */
          justify-content: flex-start; /* Ürünleri sola hizala */

          .product {
            font-size: 18px; /* Ürün yazı boyutunu küçült */
            min-width: 120px; /* Ürün kutusunu küçült */
            height: 100px; /* Yüksekliği küçült */
            margin-right: 10px; /* Aralığı daralt */
            margin-left: 10px; /* Aralığı daralt */
          }
        }

        .product-context {
          font-size: 16px; /* Paragraf yazı boyutunu küçült */
          line-height: 22px; /* Satır yüksekliğini ayarla */
        }
      }
    }
  }
}
