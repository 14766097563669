.partners {
  display: flex;
  justify-content: center;
  height: auto;
  margin: 60px 0;
  .partners-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    .partners-header {
      display: flex;
      justify-content: space-between;
      font-size: 22px;
      align-items: center;
      width: 100%;
      padding: 20px 22px;
      i {
        cursor: pointer;
        &:hover {
          cursor: pointer;
          color: #5b5b5b;
        }
      }
    }
    .cart-area {
      display: flex;
      overflow-x: auto;
      cursor: grab;
      width: 1136px;
      scrollbar-width: none;
      .cart {
        min-width: 240px;
        height: 150px;
        box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 22px 30px;
        user-select: none;
        cursor: grab;
        img {
          padding: 15px;
          width: 100%;
          height: 100%;
          user-select: none;
          cursor: grab;
          -webkit-user-drag: none;
        }
      }
    }
  }
}

// ToDo: Mobil için revize edilecek

@media (max-width: 768px) {
  .partners {
    flex-direction: column; /* Mobilde dikey hizala */
    margin: 30px 0; /* Mobilde margin azalt */

    .partners-area {
      width: 100%; /* Tam genişlikte olsun */
      padding: 0 10px; /* Mobilde içerik kenar boşluğunu küçült */

      .partners-header {
        font-size: 18px; /* Başlık yazı boyutunu küçült */
        padding: 10px 12px; /* Daha küçük padding */
      }

      .cart-area {
        width: 100%; /* Kart alanı mobilde tam genişlikte olsun */
        padding-bottom: 10px; /* Alt boşluk azalt */
        justify-content: flex-start; /* Kartları sola hizala */
        .cart {
          min-width: 180px; /* Kart genişliğini küçült */
          height: 120px; /* Kart yüksekliğini küçült */
          margin: 10px 10px 20px; /* Kartların arasındaki boşlukları azalt */
        }
      }
    }
  }
}
