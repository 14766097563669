.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 40px;
  padding: 70px 0;
  h1 {
    font-size: 24px;
    font-weight: 700;
    color: #212121;
    margin-left: 10px;
  }

  .contact-area,
  .location-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;

    h1 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .expert-area {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 100px;

      .first-expert,
      .second-expert {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 10px;

        span {
          font-size: 18px;
          font-weight: 700;
          color: #131313;

          &:first-child {
            width: 100%;
            text-align: center;
          }

          i {
            font-size: 18px;
            margin-right: 5px;
            color: #919191;
          }

          a {
            text-decoration: none;
            color: #919191;
          }
        }
      }
    }
  }

  .location-area {
    .first-expert,
    .second-expert {
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        svg,
        p {
          color: #919191;

          span {
            color: #919191 !important;
          }
        }
      }
    }
  }

  // Mobil görünüm için düzenlemeler
  @media screen and (max-width: 768px) {
    gap: 20px;
    padding: 40px 0;
    margin-top: -90px;
    h1 {
      font-size: 22px;
    }

    .contact-area,
    .location-area {
      padding: 10px;
      h1 {
        margin-bottom: 0;
      }

      .expert-area {
        flex-direction: column;
        gap: 20px;

        .first-expert,
        .second-expert {
          align-items: center;
          span {
            font-size: 16px;
          }

          i {
            font-size: 16px;
          }

          a {
            font-size: 16px;
          }
        }
      }
    }
  }

  // Küçük mobil cihazlar için düzenlemeler
  @media screen and (max-width: 480px) {
    gap: 10px;
    padding: 20px 0;

    .contact-area,
    .location-area {
      h1 {
        font-size: 20px;
      }

      .expert-area {
        gap: 0;
        .first-expert {
          padding: 20px;
          border-bottom: 1px solid #313131;
        }
        .second-expert {
          padding: 20px;
        }

        .first-expert,
        .second-expert {
          span {
            font-size: 20px;
          }

          i {
            font-size: 18px;
          }

          a {
            font-size: 18px;
          }
        }
      }
    }
  }
}
